import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import yellowOne from "../../images/1-yellow.svg";
import yellowTwo from "../../images/2-yellow.svg";
import yellowThree from "../../images/3-yellow.svg";
import CalendarFormSwitcher from "../../components/CalendarFormSwitcher";

const BrandingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			yourBrandImg: wpMediaItem(title: { eq: "need-help-ads" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			linvaLogoImg: wpMediaItem(title: { eq: "lingvahive-logo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			RyanImg: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			TootLogoImg: wpMediaItem(title: { eq: "Toot-Hill-Master-Logo 1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Branding Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Branding",
				item: {
					url: `${siteUrl}/dubai/branding`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/branding`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`BRANDING \n<span class="text-secondary">& DESIGN DUBAI</span>`}
						description="Building a unique brand that represents your business."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-we-do"
								>
									What we do
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
									The significance of brand identity
								</h2>
								<p>
									First impressions cannot be underestimated. That's why
									creating a strong brand is crucial, studies have shown that
									customers often form an opinion of a company within the first
									seven seconds of their first interaction.
								</p>
								<p>
									A well-crafted brand that effectively reflects your business
									values, showcases your expertise, and differentiates you from
									competitors can encourage potential customers to trust that
									you are the business for them. Your brand's logo, colour
									scheme, and marketing materials all play an important role in
									establishing a professional appearance, and RJM Digital will
									collaborate with you to develop your brand.
								</p>

								<Button
									className="px-4 py-2 mt-4 mb-5 w-100 cta-btn w-md-auto fs-5 white-link-yellow mb-lg-0"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch
								</Button>
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.yourBrandImg.gatsbyImage}
										alt={data.yourBrandImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section
					id="what-we-do"
					className="pb-5 bg-light-grey position-relative pb-lg-7"
				>
					<div className="position-relative bg-dark-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center ">
								<Col lg={6} className="">
									<h2 className="mb-4 text-white display-5">
										RJM Digital can help you
									</h2>
									<p className="text-white">
										We have established numerous brands that are loved by both
										business owners and their customers. Regardless of whether
										you're starting from scratch or refining an existing brand,
										we're here to lend a hand.
									</p>
									<p className="text-white">
										Here are a few of our branding projects completed for some
										of our customers.
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div style={{ top: "-5rem" }} className="position-relative">
						<Container>
							<Row className="g-6">
								<Col lg={4}>
									<div
										className="px-4 bg-white d-flex justify-content-center align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.linvaLogoImg.gatsbyImage}
												alt={data.linvaLogoImg?.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="px-4 bg-white d-flex justify-content-center align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.RyanImg.gatsbyImage}
												alt={data.RyanImg?.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="px-4 bg-white d-flex justify-content-center align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.TootLogoImg.gatsbyImage}
												alt={data.TootLogoImg?.altText}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				{/* <Logos /> */}

				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Branding" />
				</section> */}
				{/* <div id="faq">
					<Faq page="Branding" />
				</div> */}
				<section className="pb-5 pb-lg-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your branding project.
								</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between align-items-center">
							<Col md={6} lg={8}>
								<h2 className="text-white display-2 ssp-bold mb-lg-5">
									ADVANTAGES OF HAVING
									<span>
										<br />{" "}
										<span className="text-secondary">A PROFESSIONAL BRAND</span>
									</span>
								</h2>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={yellowOne} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Stands out</h3>
									<p className="text-white">
										Differentiation with bespoke design in a busy market, is the
										one way your target customers will choose your business and
										develop trust.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={yellowTwo} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Effective</h3>
									<p className="text-white">
										A professional brand enhances a business's credibility and
										boosts the effectiveness of its marketing. A distinctive
										brand increases customer engagement and customer loyalty.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={yellowThree} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Profitability</h3>
									<p className="text-white">
										By aligning your brand with your customers' values, you
										build trust which will inevitably make them feel comfortable
										enough to buy your products or services.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <OSSection
          dubai
          link1="/dubai/web-design"
          text1="WEB DESIGN"
          link2="/dubai/e-commerce-websites"
          text2="E-COMMERCE"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        /> */}
			</Layout>
		</>
	);
};

export default BrandingPage;
